import { l, le, lj, ls, lw, j, s } from './utils/devUtils';

window.$localMode = import.meta.env.NODE_ENV === "development" && import.meta.env.VITE_ENV === "local";
window.l = l;
window.lj = lj;
window.le = le;
window.ls = ls;
window.lw = lw;
window.j = j;
window.s = s;

window.l('import.meta.env', import.meta.env);

import Configuration from "./services/configuration";

window.$devMode =
    import.meta.env.DEV
  || Configuration.value('backendUrl')?.includes("staging")
  || Configuration.value('backendUrl')?.includes("dev");

console.log('window.$devMode', window.$devMode);

export default window;
