const DASHBOARD_URL = "/";

const menuItems = [

 
    {
        title: "nav.dashboard",
        roles: ["ROLE_ROOT", "ROLE_SUPERADMIN", "ROLE_ADMIN", "ROLE_MEMBER"],
        link: DASHBOARD_URL,
        icon: "mdi-view-dashboard",
        new: false,
        beta: false,
        separator: false,
    },
    {
        title: "nav.discussion",
        link: "/discussion",
        icon: "mdi-forum",
        new: false,
        beta: false,
        separator: false,
        featureFlag: "DISCUSSION_MODE",
    },
    {
        title: "nav.reports",
        roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        link: "/reports",
        icon: "mdi-table",
        new: false,
        beta: false,
        separator: false,
    },
    {
        title: "nav.feedForwards",
        roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        link: "/feedforwards",
        icon: "mdi-table",
        new: false,
        beta: true,
        separator: false,
        featureFlag: "AI_SUMMARIZATION_ENABLED",
    },
    {
        title: "nav.ffanalysis",
        roles: ["ROLE_SUPERADMIN"],
        link: "/feedforwardanalysis",
        icon: "mdi-table",
        new: false,
        beta: false,
        separator: false,
        devOnly: true,
    },
    //////////////////////
    {
        title: "nav.teamTasks",
        link: "/team-tasks",
        icon: "mdi-clipboard-text-outline",
        new: false,
        beta: false,
        upperSeparator: true,
        separator: false,
        featureFlag: "DISCUSSION_MODE",
    },
    {
        title: "nav.delegatedTasks",
        roles: ["ROLE_SUPERADMIN"],
        link: "/delegated-tasks",
        icon: "mdi-clipboard-alert-outline",
        new: false,
        beta: false,
        separator: false,
        featureFlag: "DISCUSSION_MODE",
    },
       //////////////////////
    {
        title: "nav.structure",
        roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        link: "/team",
        icon: "mdi-file-tree",
        new: false,
        beta: false,
        separator: false,
        upperSeparator: true,
    },
    {
        title: "nav.members",
        roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        link: "/members",
        icon: "mdi-account-details",
        new: false,
        beta: false,
        separator: true,
    },
    ///////////////////////

    {
        title: "nav.questions",
        roles: ["ROLE_SUPERADMIN", "ROLE_ADMIN"],
        link:  "/question-editor",
        icon: "mdi-comment-question-outline",
        new: false,
        beta: false,
        separator: false,
    },
    {
        title: "nav.adhoc",
        link: "/adhoc-survey",
        icon: "mdi-clock-fast",
        new: false,
        beta: false,
        separator: false,
        featureFlag: "ADHOC_SURVEY",
    },
    ////////////////////////////
    {
        title: "nav.profile",
        link:"/my-profile",
        icon: "mdi-account", // TODO: V3 end
        new: false,
        beta: false,
        upperSeparator: true,
        separator: false,
    },

    {
        title: "nav.surveyDefinitions",
        link:"/survey-definitions",
        role: ["ROLE_SUPERADMIN"],
        icon: "mdi-table", // TODO: V3 end
        new: false,
        beta: true,
        upperSeparator: true,
        separator: false,
        devOnly: true,
    }
];

function checkAvailability(item, featureFlags, userRoles) {
    //show menu items that are visible for everyone
    if (
        !item.hasOwnProperty("roles") &&
        !item.hasOwnProperty("featureFlag")
    ) {

        return true;
    } else {
        let isAuthorised = false;
        //check for roles
        if (item.hasOwnProperty("roles")) {
            let rolePermission = false;
            item.roles.forEach(role => {
                if (userRoles.includes(role)) {
                    rolePermission = true;
                }
            });

            if (rolePermission) {
                isAuthorised = true;
            } else {
                //stop here, don't have to check features since role doesn't allow rendering

                return false;
            }
        }

        // check for 'dont show if present' feature flags
        if (item.hasOwnProperty("excludeFeatureFlag")
            && featureFlags && featureFlags.includes(item.excludeFeatureFlag)) {

                return false;
        }

        // check for 'show if present' feature flags
        if (item.hasOwnProperty("featureFlag")) {
            isAuthorised =
                featureFlags && featureFlags.includes(item.featureFlag);
        }

        return isAuthorised;
    }
}

const setAvailableMenuItems = function(featureFlags, userRoles) {
    //returns valid menu items

    let mainItems = menuItems.filter(item => {
        return checkAvailability(item, featureFlags, userRoles);
    });

    return mainItems;
};

export { setAvailableMenuItems };
