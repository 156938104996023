export default class Configuration {
    static wasConfigLogged = false

    static _config = {
        authUrl: '$VITE_AUTH_BASE_URL',
        backendUrl: '$VITE_API_BASE_URL',
        loginUrl: '$VITE_DM_LOGIN_URL',
        csrfCookieName: '$VITE_CSRF_COOKIE_NAME'
    }

    static get CONFIG () {
        if (!this.wasConfigLogged) {
            this.wasConfigLogged = true;
            window.l('CONFIG:', Configuration.CONFIG);
        }

        return this._config;
    }

    static booleanValue(name) {
       let value = this.value(name) === 'true';
       return value;
    }

    static value (name) {
        if (!(name in this.CONFIG)) {
            window.le(`Configuration: There is no key named "${name}"`)
            return
        }

        let value = this.CONFIG[name]

        if (value.startsWith('$VITE_')) {
            const envName = value.substr(1)
            const envVars = import.meta.env

            value = envVars[envName] || value;

            if (value.startsWith('$VITE_')) {
                // Explicit fallback only for selected keys
                const fallbacks = {
                    VITE_CSRF_COOKIE_NAME: 'XSRF-TOKEN',
                    // other keys can remain without defaults if preferred
                };

                if (envName in fallbacks) {
                    window.l(`Configuration: "${envName}" is not defined in ENV vars, using fallback.`);
                    value = fallbacks[envName];
                } else {
                    window.le(`Configuration: "${envName}" is not defined in ENV vars, and no fallback is provided.`);
                    value = undefined; // or handle accordingly
                }
            }
        }

        return value
    }
}
