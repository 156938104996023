import axios from "axios";
import Configuration from "@/services/configuration";

const httpAuthClient = axios.create({
    baseURL: Configuration.value('authUrl'),
    timeout: 20000,
    withCredentials: true
});

const CSRF_COOKIE_NAME = Configuration.value('csrfCookieName');



const END_POINT = '/api/v1';

const csrfHandshake = () => httpAuthClient.get(`${END_POINT}/csrf-token`);

const signin = (user) => httpAuthClient.post(`${END_POINT}/login`,{
        username: user.username,
        password: user.password
});


const accountGet =  () => httpAuthClient.get(`${END_POINT}/account`);

const accountUpdate =  (account) => httpAuthClient.put(`${END_POINT}/account`, account);

const initEmailChange = (email) => httpAuthClient.post(`${END_POINT}/account/email-change`, {
    newEmail: email
});

const myAccount =  () => httpAuthClient.get(`${END_POINT}/user/myaccount`);

const profiles =  () => httpAuthClient.get(`${END_POINT}/user/profiles`);


const logout = () => { window.l('before logout call'); return httpAuthClient.post(`/logout`); };

const token = () => { window.l('before token call'); return httpAuthClient.post(`${END_POINT}/token`); };

const selectProfile = (membershipId) => httpAuthClient.post(`${END_POINT}/selectProfile`, {
    membershipId: membershipId
});


const urlLogin = (tokenRequest) => httpAuthClient.post(`${END_POINT}/login/url`,{
    token: tokenRequest
});

const forgotPassword  = (email) => httpAuthClient.post(`${END_POINT}/forgotPassword/`,{
    email: email
});

const resetPasswordAuthentication = (resetToken) => httpAuthClient.post(`${END_POINT}/resetPassword/verify`, {
    token: resetToken
});

const resetPassword = (userResetPasswordRequest) => httpAuthClient.post(`${END_POINT}/resetPassword`, {
    token: userResetPasswordRequest.token,
    password: userResetPasswordRequest.password
});

const verifyEmail = (emailVerificationToken) => httpAuthClient.post(`${END_POINT}/account/email-change/verify`, {
    token: emailVerificationToken
});

const activatePassword = () => httpAuthClient.post(`${END_POINT}/activatePassword`);



const trigger2fa = () => httpAuthClient.post(`${END_POINT}/2fa/trigger`);

const verify2fa = (code) => httpAuthClient.post(`${END_POINT}/2fa/verify`, {
    code: code
});

const stopImpersonation = () => httpAuthClient.post(`${END_POINT}/admin/impersonate/stop`);


const errorKeysMap = {
    title: 'error',
    detail: 'message',
    instance: 'path',
};

httpAuthClient.interceptors.response.use(response => {
    const isFullHttpLoggingEnabled = false;
    if ($devMode && isFullHttpLoggingEnabled) {
        console.group(`httpAuthClient response %c${response?.config?.url}:`,
                    "font-weight: bold; color: green");
            lj('request', response?.request);
            lj('data', response?.data);
            lj('response', response);
        console.groupEnd();
    }
    return response;
}, (error) => {
    error = error || new Error('HTTP Auth Client error');
    //lj("httpAuthClient error: ", error);
    lj("httpAuthClient error.response: ", error.response);

    // hydrate message to support legacy data format
    let errorData = error.response?.data;
    if (errorData) {
        Object.keys(errorKeysMap).forEach((key) => {
            if (!errorData[errorKeysMap[key]] && errorData[key]) {
                errorData[errorKeysMap[key]] = errorData[key];
            }
        });

        error.message = errorData.message;
    }

    throw error;
});

httpAuthClient.interceptors.request.use((config) => {
    // Read the token from the 'XSRF-TOKEN' cookie
    const token = getCookie(CSRF_COOKIE_NAME);
    if (token) {
        // By default, Spring expects the header name 'X-XSRF-TOKEN'
        config.headers['X-XSRF-TOKEN'] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

function getCookie(name) {
    // This regex captures the value of `name` from `document.cookie`.
    // If not found, it returns an empty string.
    const regex = new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*=\\s*([^;]*).*$)|^.*$');
    return document.cookie.replace(regex, '$1');
}


export {
    verifyEmail,
    csrfHandshake,
    forgotPassword,
    logout,
    resetPassword,
    activatePassword,
    resetPasswordAuthentication,
    signin,
    token,
    urlLogin,
    trigger2fa,
    verify2fa,
    stopImpersonation,
    myAccount,
    profiles,
    selectProfile,
    accountGet,
    accountUpdate,
    initEmailChange
};
